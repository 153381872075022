import	React, {useState, useEffect}	from 'react'
import	moment							from 'moment/min/moment-with-locales'
import	* as API						from '../ressources/API'

import	'./style.css'
moment.locale('fr');

function	Ranking(props) {
	function	setLocalStorage(key, value) {localStorage.setItem(key, JSON.stringify(value))}
	function	getLocalStorage(key, base) {try {return JSON.parse(localStorage.getItem(key) || base)} catch (e) {return base}}


	const	[dateStart, set_dateStart] = useState(moment().startOf('day').subtract(1, 'week'))
	const	[dateEnd, set_dateEnd] = useState(moment().startOf('day'))

	const	[multiDelete, set_multiDelete] = useState({})

	const	[stats, set_stats] = useState([])
	// const	[rank, set_rank] = useState([])

	const	[token, set_token] = useState(getLocalStorage('token', null) || '')

	const	[login, set_login] = useState('')
	const	[password, set_password] = useState('')

	const	[load, set_load] = useState()

	useEffect(getData, [dateStart, dateEnd, token])

	function connect() {
		API.login({login, password})
		.then(response => {
			if (!response || !response.token)
				return window.alert('Mauvais pseudo ou mot de passe')
	
			set_token(response.token)
			setLocalStorage('token', response.token)
		})
	}

	function	getData() {
		set_load(true)
		set_multiDelete({})
		if (!token)
			return set_load(false)

		API.getStats({start: dateStart.toISOString(true), end: dateEnd.toISOString(true), token})
		.then(response => {
			set_load(false)
			if (!response || response.error)
				return
			if (response === 'error token') {
				setLocalStorage('token', '')
				return set_token('')
			}

			set_stats(response)
		})
	}

	function	downloadStats() {
		const	element = document.createElement('a')
		let		text = `SEP=,\nAdresse mail référente,Date de création,Mail,Nom,Date de naissance,Code postal,Lieu,Lien résumé\n`

		stats.forEach(statsGame => {
			if (!statsGame || !statsGame.matchs || statsGame.matchs.length === 0 || !statsGame.matchs[0].team)
				return

			let	count = 0

			if (statsGame.frontForm && statsGame.frontForm.players) {
				statsGame.frontForm.players.forEach(form => {
					text += `${statsGame.matchs[0].team.mail},${moment(statsGame.startDateTime).format('DD/MM')} ${moment(statsGame.startDateTime).format('LT')},${form.email},${form.name},${moment(form.date).format('DD/MM/YYYY')},${form.postalCode},${statsGame.location.name},${window.location.origin}/game?id=${statsGame._id}\n`
					count++
				})
			}

			if (count === 0)
				text += `${statsGame.matchs[0].team.mail},${moment(statsGame.startDateTime).format('DD/MM')} ${moment(statsGame.startDateTime).format('LT')},,,,,${statsGame.location.name},${window.location.origin}/game?id=${statsGame._id}\n`
		})

		element.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
		element.setAttribute('download', `Stats_${moment().format('DD_MM_YY')}.csv`);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}

	function	deleteGame(id) {
		if (!window.confirm(`Supprimer la partie ?`))
			return;

		set_multiDelete({...multiDelete, [id]: false})

		API.deleteGame({token, _id: id})
		.then(() => getData())
	}

	function	deleteMultiGame() {
		if (!window.confirm(`Supprimer les ${findDelete} parties ?`))
			return;

		const	toArray = Object.entries(multiDelete);

		const	check = (i) => {
			if (!toArray[i])
				return getData();

			if (toArray[i][1] === false)
				return check(i + 1);
			
			API.deleteGame({token, _id: toArray[i][0]})
			.then(() => check(i + 1))
		}
		check(0)
	}

	function	renderStats(name) {
		const	render = []

		if (!stats)
			return

		stats.forEach(statsGame => {
			if (!statsGame || !statsGame.matchs || statsGame.matchs.length === 0 || !statsGame.matchs[0].team)
				return

			const	renderInfo = {}
			let		count = 0
			
			if (statsGame.frontForm && statsGame.frontForm.players) {
				statsGame.frontForm.players.forEach((form, i) => {
					renderInfo[i] = []
					renderInfo[i].push(<td>{form.email}</td>)
					renderInfo[i].push(<td>{form.name}</td>)
					renderInfo[i].push(<td>{form.date ? moment(form.date).format('DD/MM/YYYY') : ''}</td>)
					renderInfo[i].push(<td>{form.postalCode}</td>)

					count++
				})
			}

			if (!renderInfo['0']) {
				count++
				renderInfo['0'] = []
				renderInfo['0'].push(<td colSpan={4}>Non remplis</td>)
			}

			render.push(
				<tr>
					<td rowSpan={count}>{statsGame.matchs[0].team.mail}</td>
					<td rowSpan={count}>{moment(statsGame.startDateTime).format('DD/MM')} {moment(statsGame.startDateTime).format('LT')}</td>
					{renderInfo['0']}
					<td rowSpan={count}>
						<a href={`/game?id=${statsGame._id}`} target={'_blank'} >
							Ouvrir
						</a>
					</td>
					<td rowSpan={count}>
						{statsGame.location ? statsGame.location.name || '' : ''}
					</td>
					<td rowSpan={count}>
						<span onClick={() => deleteGame(statsGame._id)} style={{cursor: 'pointer', 'color': 'blue', 'textDecoration': 'underline'}}>Supprimer</span>
						<input checked={multiDelete[statsGame._id]} onClick={() => set_multiDelete({...multiDelete, [statsGame._id]: !multiDelete[statsGame._id]})} type={'checkbox'} style={{marginLeft: 20}} />
					</td>
				</tr>
			)

			for (let i = 1; renderInfo[i]; i++)
				render.push(<tr>{renderInfo[i]}</tr>)
		})

		return render
	}

	if (!token) {
		return (
			<div className={'adminLogin'}>
				<div>
					<label>{'Pseudo'}</label><br />
					<input value={login} onChange={val => set_login(val.target.value)} /><br /><br />
					<label>{'Mot de passe'}</label><br />
					<input type={'password'} value={password} onChange={val => set_password(val.target.value)} /><br /><br />

					<button onClick={connect}>{'Se connecter'}</button>
				</div>
			</div>
		)
	}

	let findDelete = 0
	let	session = 0
	let	match = 0

	Object.entries(multiDelete)
	.forEach(e => {
		if (e[1] === true)
			findDelete++
	})

	if (stats) {
		stats.forEach(statsGame => {
			if (!statsGame || !statsGame.matchs || statsGame.matchs.length === 0 || !statsGame.matchs[0].team)
				return

			session++
			match += statsGame.matchs.length
		})
	}

	return (
		<div className={`mainAdmin`}>
			<br />

			<div>
				<input type={'date'} value={dateStart.format('YYYY-MM-DD')} onChange={val => set_dateStart(moment(val.target.value, 'YYYY-MM-DD'))} />{' - '}
				<input type={'date'} value={dateEnd.format('YYYY-MM-DD')} onChange={val => set_dateEnd(moment(val.target.value, 'YYYY-MM-DD'))} />
			</div>

			<br />

			<div>
				<button onClick={downloadStats}>{'Télécharger'}</button>
			</div>

			<br />

			<div className='bulleAdmin'>
				<div>
					<span>Session jouées</span>
					<span>{session}</span>
				</div>

				<div>
					<span>Parties jouées</span>
					<span>{match}</span>
				</div>
			</div>

			<table className={'tableAdmin'}>
				<thead>
					<tr>
						<th rowSpan={2}>{`Adresse mail référente`}</th>
						<th rowSpan={2}>{`Date de création`}</th>
						<th colSpan={4}>{`Informations personnels`}</th>
						<th rowSpan={2}>{`Lien résumé`}</th>
						<th rowSpan={2}>{`Lieu`}</th>
					</tr>
					<tr>
						<th>{`Mail`}</th>
						<th>{`Nom`}</th>
						<th>{`Date de naissance`}</th>
						<th>{`Code postal`}</th>
					</tr>
				</thead>
				<tbody>
					{!load && renderStats()}
				</tbody>
			</table>

			{load &&
				<div className={'loaderContainer'}>
					<div className={'loader'} />
				</div>
			}

			{findDelete > 0 &&
				<div className={'adminMultiDelete'} onClick={deleteMultiGame}>
					{`Supprimer ${findDelete} parties`}
				</div>
			}
		</div>
	)
}

export default Ranking
